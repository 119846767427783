export const colors = {
  header: "#ffffff",
  footer: "#1085AF",
  primary: "#0300E6", // deep blue color
  secondary: "#1085AF", // turquoise color
  tertiary: "#e1e1e1", // gray color
  disabled: "#B0B0B0", // lightish gray
  success: "#17A221", // that green color
  error: "#AA0000", // red color
  warning: "#CC5500", // orange color
  borders: "#E0E0E0", // light gray color
  whatsapp: "#25D366", // whatsapp green color
  //TODO: add more colors as needed
};
